import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { createProvider } from './vue-apollo'
import './scss/main.scss'
import VueMeta from 'vue-meta'
import VueMarkdown from 'vue-markdown'
import VueMoment from 'vue-moment'
import VueGtag from 'vue-gtag'
// import * as Sentry from '@sentry/browser'
// import { Vue as VueIntegration } from '@sentry/integrations'
// import { Integrations } from '@sentry/tracing'

import LogRocket from 'logrocket'

const isBlogRoute = window.location.pathname.includes('/blog')
if (process.env.NODE_ENV === 'production' && !isBlogRoute) {
  LogRocket.init('oilaud/gradbee')
}

Vue.use(VueGtag, {
  config: { id: 'UA-64472626-1' }
}, router)

Vue.use(VueMoment)
Vue.use(VueMeta)
Vue.component('vue-markdown', VueMarkdown)
//
// Sentry.init({
//   dsn: 'https://88d5989a375c423e96ddd1b29b306aae@o40500.ingest.sentry.io/92042',
//   integrations: [
//     new VueIntegration({
//       Vue,
//       logErrors: true
//     })
//     // new Integrations.BrowserTracing()
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
