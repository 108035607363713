import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fbStatus: null,
    locationService: null,
    onboarding: null,
    fetchFeed: false
  },
  getters: {
    isOnboard: state => state.onboarding === '1' || state.onboarding
  },
  mutations: {
    SET_FB_STATUS (state, data) {
      state.fbStatus = data
    },
    SET_LOCATION_SERVICE (state, data) {
      state.locationService = data
    },
    SET_ONBOARDING (state, data) {
      state.onboarding = data
    },
    SET_FETCH_FEED (state, data) {
      state.fetchFeed = data
    },
    UNSET_USER (state) {
      state.onboarding = null
    }
  },
  actions: {
    setFbStatus ({ commit }, data) {
      commit('SET_FB_STATUS', data)
    },
    setLocationService ({ commit }, data) {
      commit('SET_LOCATION_SERVICE', data)
    },
    setOnboarding ({ commit }, data) {
      commit('SET_ONBOARDING', data)
    },
    setFetchFeed ({ commit }, data) {
      commit('SET_FETCH_FEED', data)
    },
    unsetUser ({ commit }) {
      commit('UNSET_USER')
    }
  },
  modules: {
  }
})
