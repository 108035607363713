<template>
  <div>
    <v-list shaped>
      <v-list-item v-for="item in world" :key="item.title" :to="{name: item.link}" link class="my-2" color="secondary" exact-active-class="sidebar-item" exact >
        <v-list-item-icon>
          <v-icon>mdi-{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="px-4">
      <v-divider></v-divider>
    </div>
    <v-list shaped>
      <v-list-item v-for="item in my" :key="item.title" :to="{name: item.link}" link class="my-1" color="secondary" exact-active-class="sidebar-item font-weight-bold" :exact="item.exact">
        <v-list-item-icon>
          <v-icon>mdi-{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="mt-5" v-if="!isMessageLayout">
        <v-btn x-large color="secondary" block :to="{name: 'createPost'}">
          Create Post <v-icon small class="ml-1">mdi-plus-thick</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    isMessageLayout: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      my: [
        { title: 'My Posts', icon: 'cards-variant', link: 'myProfile', exact: true },
        { title: 'Interested', icon: 'heart-plus', link: 'myApplications', exact: true },
        { title: 'Bookmarks', icon: 'bookmark-multiple', link: 'myBookmarkedProjects', exact: true },
        { title: 'Saved Profiles', icon: 'account-box-multiple', link: 'mySavedProfiles', exact: false }
      ]
    }
  },
  computed: {
    world () {
      return [
        {
          title: this.isGuest ? 'Posts' : 'Feed',
          icon: 'cards',
          link: this.isGuest ? 'posts' : 'feed',
          exact: false
        },
        { title: 'Students', icon: 'account-group', link: 'profiles' }
      ]
    },
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  }
}
</script>

<style scoped lang="scss">
  .sidebar-item {
    &::before {
      opacity: 0 !important;
    }
    &:hover::before {
      opacity: 0.08 !important;
    }
  }
  .v-list-item__icon {
    margin-right: 1.25rem !important;
  }
</style>
