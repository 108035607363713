<template>
  <v-app-bar app clipped-left clipped-right elevation="2" >
    <v-app-bar-nav-icon @click="$emit('drawerClicked')" v-if="$vuetify.breakpoint.mdAndDown && !isGuest"></v-app-bar-nav-icon>
    <v-btn
      icon
      :to="{name: isGuest ? 'login' : 'feed'}"
      exact
    >
      <img :src="$vuetify.theme.dark ? require('@/assets/images/logo.svg') : require('@/assets/images/logoDark.svg')" width="20"  alt="logo"/>
    </v-btn>
    <v-spacer></v-spacer>
    <div v-if="isGuest" class="d-flex align-center">
      <theme/>
      <v-btn :to="{name: 'login'}" depressed color="transparent" class="mr-3">
        Login
      </v-btn>
      <v-btn :to="{name: 'register'}" outlined>
        Get Started
      </v-btn>
    </div>
    <div v-else>
      <v-btn
        :to="{name: 'createPost'}"
        :icon="$vuetify.breakpoint.smAndDown"
        text
        class="ml-1 text--secondary"
      >
        <v-icon>mdi-plus</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Create Post</span>
      </v-btn>
      <v-btn
        :to="{name: 'messages'}"
        :icon="$vuetify.breakpoint.smAndDown"
        text
        class="ml-1 text--secondary">
        <v-icon>mdi-chat-processing-outline</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Messages</span>
      </v-btn>
    </div>
    <v-menu left bottom offset-y min-width="200" v-if="!isGuest">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="ml-1 text--secondary">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :to="{name: 'myProfile'}">
          <v-list-item-title>
            <v-icon class="mr-1">mdi-account</v-icon> My Profile
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeTheme()" >
          <v-list-item-title v-show="!dark">
            <v-icon class="mr-1">mdi-weather-night</v-icon> Dark Mode
          </v-list-item-title>
          <v-list-item-title v-show="dark">
            <v-icon class="mr-1">mdi-white-balance-sunny</v-icon> Light Mode
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('logout')">
          <v-list-item-title>
            <v-icon class="mr-1">mdi-exit-to-app</v-icon> Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Theme from '../components/Theme'
export default {
  data () {
    return {
      dark: localStorage.getItem('GB_DARK') === 'true'
    }
  },
  props: {
    drawer: {
      default: null
    },
    currentUser: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  },
  methods: {
    changeTheme () {
      this.$vuetify.theme.dark = !this.dark
      localStorage.setItem('GB_DARK', !this.dark)
      this.dark = !this.dark
    }
  },
  components: {
    Theme
  }
}
</script>

<style scoped>

</style>
