<template>
  <div>
    <default-layout-component hide-left-sidebar hide-right-sidebar :fillMobile="true" />
  </div>
</template>

<script>
import DefaultLayoutComponent from '@/components/DefaultLayoutComponent/'
export default {
  metaInfo () {
    return {
      title: 'Create a Post | Gradbee'
    }
  },
  components: {
    DefaultLayoutComponent
  }
}
</script>
