export default {
  computed: {
    lgScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return true
        case 'lg':
          return false
        case 'xl':
          return false
      }
      return false
    },
    mdScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
      }
      return true
    },
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    bg () {
      return this.$vuetify.theme.themes[this.theme].background
    },
    header () {
      return this.$vuetify.theme.themes[this.theme].header
    }
  }
}
