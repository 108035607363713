import Sidebar from '@/components/Sidebar/'
import AppBar from '@/components/AppBar/'
import CURRENTUSER from '../graphql/currentUser.graphql'
import breakPoints from '../mixins/breakPoints'
import { onLogout } from '../vue-apollo'

export default {
  components: {
    Sidebar,
    AppBar
  },
  props: {
    fillMobile: {
      type: Boolean,
      default: false
    },
    hideLeftSidebar: {
      type: Boolean,
      default: false
    },
    hideRightSidebar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawer: false,
      rightDrawer: false,
      currentUser: {}
    }
  },
  methods: {
    logout () {
      onLogout(this.$apollo.provider.defaultClient)
      this.$apollo.provider.defaultClient.cache.reset()
      this.$apollo.queries.currentUser.stop()
      this.$router.replace({ name: 'login' })
      this.$store.dispatch('unsetUser')
    },
    async fetchCurrentUser () {
      try {
        return await this.$apollo.queries.currentUser.refetch()
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      }
    }
  },
  apollo: {
    currentUser: {
      query: CURRENTUSER,
      error () {
        this.logout()
      },
      skip () {
        return !localStorage.getItem('apollo-token')
      }
    }
  },
  mixins: [breakPoints]
}
