export const light = {
  header: '#ffffff',
  background: '#f5f5f9',
  primary: '#0B132B',
  secondary: '#09054e',
  accent: '#FBF5F3',
  error: '#FF5252',
  info: '#286FFF',
  success: '#28b67c',
  warning: '#E8C547'
}

export const dark = {
  header: '#1E1E1E',
  primary: '#286FFF',
  secondary: '#286FFF',
  accent: '#33415c',
  error: '#FF5252',
  info: '#286FFF',
  anchor: '#286FFF',
  success: '#28b67c',
  warning: '#E8C547'
}
