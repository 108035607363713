import { render, staticRenderFns } from "./Access.vue?vue&type=template&id=691e87a1&scoped=true&"
import script from "./Access.vue?vue&type=script&lang=js&"
export * from "./Access.vue?vue&type=script&lang=js&"
import style0 from "./Access.vue?vue&type=style&index=0&id=691e87a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691e87a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VMain})
