import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { light, dark } from '../assets/theme/theme'

Vue.use(Vuetify)

export default new Vuetify({
  customProperties: true,
  theme: {
    dark: localStorage.getItem('GB_DARK') === 'true',
    options: {
      customProperties: true
    },
    themes: {
      light: light,
      dark: dark
    }
  }
})
