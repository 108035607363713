<template>
  <div>
    <messages-layout-component />
  </div>
</template>

<script>
import MessagesLayoutComponent from '../components/MessagesLayoutComponent.vue'
export default {
  components: {
    MessagesLayoutComponent
  }
}
</script>
