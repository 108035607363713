<template>
  <v-app id="access">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import breakPoints from '../mixins/breakPoints'
export default {
  data () {
    return {
      drawer: false,
      switch1: false
    }
  },
  mixins: [breakPoints]
}
</script>
<style lang="scss" scoped>
  h2 {
    cursor: pointer;
  }
</style>
