<template>
  <v-footer color="transparent" padless class="pa-0" dark absolute app>
    <v-row justify="center" no-gutters>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'about'}">
        About Us
      </v-btn>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'policy'}">
        Privacy Policy
      </v-btn>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'terms'}">
        Terms
      </v-btn>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'profiles'}">
        Users
      </v-btn>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'blog'}">
        Blog
      </v-btn>
      <v-btn color="white" text class="my-2 smaller" :to="{name: 'posts'}">
        Posts
      </v-btn>
      <v-col :cols="12" class="text-center mb-3 smaller">
        <v-icon small class="mr-1">mdi-copyright</v-icon><span>{{ new Date().getFullYear() }} - </span> <strong> Gradbee Inc.</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .smaller {
    font-size: 0.85rem;
  }
</style>
