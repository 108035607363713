<template>
  <v-app id="public">
    <public-header />
    <v-main>
      <router-view></router-view>
    </v-main>
    <public-footer />
  </v-app>
</template>

<script>
import PublicHeader from '../components/PublicHeader'
import PublicFooter from '../components/PublicFooter'
export default {
  components: {
    PublicHeader,
    PublicFooter
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    cursor: pointer;
  }
</style>
