<template>
  <div>
    <default-layout-component :fillMobile="true" />
  </div>
</template>

<script>
import DefaultLayoutComponent from '@/components/DefaultLayoutComponent/'
export default {
  components: {
    DefaultLayoutComponent
  }
}
</script>
