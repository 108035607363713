<template>
  <v-app id="feed" :style="{background: bg}">
    <app-bar
      :drawer="drawer"
      @drawerClicked="drawer = !drawer"
      @logout="logout"
      :currentUser="currentUser"
      :style="{background: header}"
    ></app-bar>
    <v-navigation-drawer v-model="drawer" app clipped :permanent="!lgScreen" floating :style="{background: bg}" width="300" v-show="!hideLeftSidebar">
      <sidebar ref="navigation" :currentUser="currentUser"/>
    </v-navigation-drawer>
    <v-main>
      <v-responsive max-width="720" class="mx-auto" :class="{'mobile-height' : fillMobile}">
        <v-container fluid class="px-1 px-sm-5 pb-0" v-if="currentUser && currentUser.meta && currentUser.meta.register_status !== 'confirm'">
          <v-alert :tile="$vuetify.breakpoint.smAndDown" icon="mdi-shield-alert" color="error" dark class="mb-0 text-body-2" elevation="1">Please verify your email <b>{{ currentUser.user_email }}</b> to start using Gradbee.</v-alert>
        </v-container>
        <router-view :current-user="currentUser" v-if="currentUser" @reFetchCurrentUser="fetchCurrentUser"></router-view>
      </v-responsive>
    </v-main>
    <v-navigation-drawer v-model="rightDrawer" app clipped :permanent="!lgScreen" floating right :style="{background: bg}" width="300" v-show="!hideRightSidebar">
<!--      <sidebar ref="rightSidebar" />-->
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import componentBase from '../mixins/componentBase'
export default {
  mixins: [componentBase]
}
</script>
