import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'Home',
      guest: true
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      layout: 'Public'
    },
    component: () => import('../views/public/About')
  },
  {
    path: '/terms',
    name: 'terms',
    meta: {
      layout: 'Public'
    },
    component: () => import('../views/public/Terms')
  },
  {
    path: '/privacy-policy',
    name: 'policy',
    meta: {
      layout: 'Public'
    },
    component: () => import('../views/public/Policy')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'Access',
      guest: true
    },
    component: () => import('../views/Access/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: 'Access',
      guest: true
    },
    component: () => import('../views/Access/Register.vue')
  },
  {
    path: '/verification/email/:token/',
    name: 'emailVerification',
    props: true,
    meta: {
      layout: 'Public'
    },
    component: () => import('../views/public/EmailVerification')
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: { name: 'profileDetails' },
    props: true,
    component: () => import('../views/Access/Onboarding/'),
    children: [
      {
        props: true,
        path: 'profile-details',
        name: 'profileDetails',
        component: () => import('../views/Access/Onboarding/CreateProfile.vue'),
        meta: {
          layout: 'Access',
          requiresAuth: true,
          parent: 'onboarding'
        }
      },
      {
        path: 'education-details',
        name: 'educationDetails',
        component: () => import('../views/Access/Onboarding/CreateEducation.vue'),
        meta: {
          layout: 'Access',
          requiresAuth: true,
          parent: 'onboarding'
        }
      },
      {
        path: 'select-categories',
        name: 'selectCategories',
        component: () => import('../views/Access/Onboarding/SelectCategories.vue'),
        meta: {
          layout: 'Access',
          requiresAuth: true,
          parent: 'onboarding'
        }
      }
    ]
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import('../views/Feed'),
    props: true,
    meta: {
      layout: 'Feed',
      requiresAuth: true
    }
  },
  {
    path: '/posts',
    name: 'posts',
    component: () => import('../views/Posts'),
    props: true,
    meta: {
      layout: 'Feed'
    }
  },
  {
    path: '/project/:slug?/:id',
    name: 'project',
    component: () => import('../views/Project'),
    props: true,
    meta: {
      layout: 'Project'
    }
  },
  {
    path: '/project/:slug?/:id/interested',
    name: 'projectInterested',
    component: () => import('../views/ProjectInterested'),
    props: true,
    meta: {
      layout: 'Project',
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'profiles',
    component: () => import('../views/Profiles'),
    props: true,
    meta: {
      layout: 'UserListing'
    }
  },
  {
    path: '/create',
    name: 'createPost',
    component: () => import('../views/CreatePost'),
    props: true,
    meta: {
      layout: 'Create',
      requiresAuth: true
    }
  },
  {
    path: '/user/:id',
    name: 'profile',
    component: () => import('../views/Profile'),
    props: true,
    meta: {
      layout: 'Profile'
    }
  },
  {
    path: '/profile',
    name: 'myProfile',
    component: () => import('../views/MyProfile'),
    props: true,
    meta: {
      layout: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/profile/interested',
    name: 'myApplications',
    component: () => import('../views/MyProfile'),
    props: true,
    meta: {
      layout: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/profile/saved-profiles',
    name: 'mySavedProfiles',
    component: () => import('../views/MyProfile'),
    props: true,
    meta: {
      layout: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/profile/bookmarks',
    name: 'myBookmarkedProjects',
    component: () => import('../views/MyProfile'),
    props: true,
    meta: {
      layout: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: () => import('../views/MyProfile'),
    props: true,
    meta: {
      layout: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/messages/:threadId?',
    name: 'messages',
    component: () => import('../views/Messages'),
    props: true,
    meta: {
      layout: 'Messages',
      requiresAuth: true
    }
  },
  {
    path: '/blog',
    name: 'blog',
    meta: {
      layout: 'Blog',
      requiresAuth: false
    },
    component: () => import('../views/Blog')
  },
  {
    path: '/blog/:slug',
    name: 'blogArticle',
    meta: {
      layout: 'Blog',
      requiresAuth: false
    },
    component: () => import('../views/Blog/Single.vue')
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue'),
    meta: {
      layout: 'Error'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'feed' || to.name === 'profiles' || to.name === 'posts') {
      if (savedPosition) {
        return { y: savedPosition.y }
      } else {
        return { x: 0, y: 0 }
      }
    }
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('apollo-token') === null) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.is_admin)) {
        if (user.is_admin === 1) {
          next()
        } else {
          next({ name: 'feed' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('apollo-token') === null) {
      next()
    } else {
      next({ name: 'feed' })
    }
  } else {
    next()
  }
})

export default router
