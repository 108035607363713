<template>
  <div class="theme-switcher">
    <v-btn @click="changeTheme()" class="mr-3" icon fab>
      <span class="text-h5" v-show="!dark">🌚</span>
      <span class="text-h5" v-show="dark">🌝</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dark: localStorage.getItem('GB_DARK') === 'true'
    }
  },
  methods: {
    changeTheme () {
      this.$vuetify.theme.dark = !this.dark
      localStorage.setItem('GB_DARK', !this.dark)
      this.dark = !this.dark
    }
  }
}
</script>
