<template>
  <v-app-bar :color="isHome ? 'transparent' : 'black'" :absolute="isHome" :fixed="!isHome" flat dark app>
    <v-toolbar-title><router-link :to="{name: 'home'}"><v-img :src="require('@/assets/images/logoWhiteFull.png')" width="130"></v-img></router-link></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn :to="{name: 'login'}" dark depressed color="transparent" class="mr-3">
      Login
    </v-btn>
    <v-btn to="register" outlined dark>
      Get Started
    </v-btn>
  </v-app-bar>
</template>
<script>
export default {
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  }
}
</script>
