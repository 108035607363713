<template>
  <v-app id="home" class="home">
    <public-header :isHome="true" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <public-footer :isHome="true" />
  </v-app>
</template>

<script>
import PublicHeader from '../components/PublicHeader'
import PublicFooter from '../components/PublicFooter'
export default {
  components: {
    PublicHeader,
    PublicFooter
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    cursor: pointer;
  }
  .home {
    height: 100%;
    background: linear-gradient(-45deg, #242ea2, #52094e, #152042, #09054e, #b9602d);
    background-size: 500% 400%;
    animation: gradient 20s ease infinite;
  }
  @media (max-width: 600px) {
    .home {
      height: 100%;
      background: linear-gradient(-45deg, #242ea2, #52094e, #152042, #09054e, #b9602d);
      background-size: 500% 140%;
      animation: gradient 20s ease infinite;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

</style>
