import store from '../store'
export const FBInit = window.FbAsyncInit = () => {
  window.FB.init({
    appId: '251483445247905',
    cookie: true,
    version: 'v8.0' // or v2.6, v2.5, v2.4, v2.3
  })
  FBAuthResponseChange()
}
export const FBAuthResponseChange = () => {
  window.FB.Event.subscribe('auth.authResponseChange', (response) => {
    // set initial FB Status here, set the state
    store.dispatch('setFbStatus', response.status)
  })
}
export const FBGetLoginStatus = () => {
  window.FB.getLoginStatus(response => {
    if (response.status === 'connected') {
      return response
    } else if (response.status === 'not_authorized') {
      // The user hasn't authorized your application.
    } else {
      // The user isn't logged in to Facebook
    }
  })
}

export const FBLogin = () => {
  window.FB.login(function (response) {
    if (response.authResponse) {
      // write mutation here
      // send the auth token to backend and in response get Gradbee's Auth Token
      console.log('Welcome!  Fetching your information.... ')
      window.FB.api('/me', function (response) {
        console.log('Good to see you, ' + response.name + '.')
      })
    } else {
      console.log('User cancelled login or did not fully authorize.')
    }
  })
}
export const FBLogout = () => {
  window.FB.logout(function (response) {
    console.log(response)
  })
}
