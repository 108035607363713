<template>
  <div>
    <default-layout-component />
  </div>
</template>

<script>
import DefaultLayoutComponent from '../components/DefaultLayoutComponent.vue'
export default {
  components: {
    DefaultLayoutComponent
  }
}
</script>
