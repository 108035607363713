<template>
  <div>
    <keep-alive :include="['Feed', 'UserListing']" v-if="!access">
      <Component :is="component"/>
    </keep-alive>
    <Component :is="component" v-else />
  </div>
</template>

<script>
import Access from '@/Layouts/Access/'
import Feed from '@/Layouts/Feed/'
import Project from '@/Layouts/Project/'
import Create from '@/Layouts/Create/'
import Loader from '@/Layouts/Loader/'
import Profile from '@/Layouts/Profile/'
import UserListing from '@/Layouts/UserListing/'
import Messages from '@/Layouts/Messages/'
import Home from '@/Layouts/Home/'
import Public from '@/Layouts/Public/'
import Blog from '@/Layouts/Blog/'
import Error from '@/Layouts/Error/'
import { FBInit } from './utils/fbApi'
import { MapsInit } from './utils/maps.js'
import CURRENTUSER from './graphql/currentUser.graphql'

export default {
  computed: {
    component () {
      return this.$route.meta.layout ? this.$route.meta.layout : 'Loader'
    },
    access () {
      return this.component === 'Access'
    }
  },
  mounted () {
    this.setTheme()
  },
  metaInfo () {
    return {
      script: [
        {
          src: 'https://connect.facebook.net/en_GB/all.js',
          async: true,
          defer: true,
          callback: () => FBInit()
        },
        {
          src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBR1k82evZNYZR6KX4jlY78BcQc4sUu2iU&libraries=places',
          async: true,
          defer: true,
          callback: () => MapsInit()
        }
      ]
    }
  },
  methods: {
    setTheme () {
      const mode = localStorage.getItem('GB_DARK')
      if (!mode || typeof mode === 'undefined') {
        localStorage.setItem('GB_DARK', true)
      }
    },
    handleOnboarding () {
      if (this.currentUser) {
        if (!this.$store.getters.isOnboard && !this.$route.meta.guest && this.$route.meta.layout !== 'Public' && this.$route.meta.parent !== 'onboarding') {
          this.$router.replace({ name: 'profileDetails' })
        }
      }
    }
  },
  apollo: {
    currentUser: {
      query: CURRENTUSER,
      result (ApolloQueryResult) {
        this.$store.dispatch('setOnboarding', ApolloQueryResult.data.currentUser.meta.onboarding_done)
        this.handleOnboarding()
      },
      skip () {
        return this.$route.meta.guest || this.$route.meta.layout === 'Public' || !localStorage.getItem('apollo-token')
      }
    }
  },
  components: {
    Access,
    Feed,
    Project,
    Loader,
    Create,
    Profile,
    Messages,
    Home,
    Public,
    Blog,
    Error,
    UserListing
  }
}
</script>
